import 'react-native-gesture-handler';
import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import Login from './components/login';
import Forgot from './components/forgot';
import Register from './components/register';
import MainView from './components/mainview';
import RecordsView from './components/records';
import TaskDetail from './components/taskDetail';
import FormView from './components/form';
import NewTask from './components/newTask';
import NewLogTask from './components/newLogTask';
import GroupView from './components/groupView';
import { createStackNavigator } from '@react-navigation/stack';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import colors from "./constants/colors";
import { logout } from "./actions/login";
import {
  Image, View, TouchableOpacity, Text, NativeModules, Platform
} from 'react-native';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import alerts from './components/components/alert';
import TitleBar from "./components/widgets/NTitlebar";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

function LoginScreen({ navigation }) {
  return (<Login navigation={navigation} />);
}
function ForgotScreen({ navigation }) {
  return (<Forgot navigation={navigation} />);
}
function RegisterScreen({ navigation }) {
  return (<Register navigation={navigation} />);
}
function MainViewScreen({ navigation }) {
  return (<MainView navigation={navigation} />);
}
function RecordsViewScreen({ navigation }) {
  return (<RecordsView navigation={navigation} />);
}

function TaskDetailScreen({ navigation }) {
  return (<TaskDetail navigation={navigation} />);
}
function FormViewScreen({ route, navigation }) {
  console.log(route.params)
  return (<FormView navigation={navigation} formId={route.params.formId} title={route.params.title} isSubmission={route.params.submission} newReport={route.params.newReport} task_id={route.params.task_id} log_group_id={route.params.log_group_id} promptMessages={route.params.promptMessages} isPallet={route.params.isPallet}/>);
}
function NewTaskScreen({ navigation }) {
  return (<NewTask navigation={navigation} />);
}
function NewLogTaskScreen({ navigation }) {
  return (<NewLogTask navigation={navigation} />);
}
function GroupViewScreen({ navigation }) {
  return (<GroupView navigation={navigation} />);
}
// function ReturnIcon({ goBack,navigation }) {
//   return (
//     <TouchableOpacity
//       // onPress={goBack}
//       onPress = {() => {
//         alerts("If you leave this form, data you have entered may not be saved. Are you sure you want to leave this form?", "",

//           [
//             { text: "Cancel", style: 'cancel', onPress: () => { console.log('cancel back') } },
//             {
//               text: 'Ok',
//               style: 'destructive',
//               // If the user confirmed, then we dispatch the action we blocked earlier
//               // This will continue the action that had triggered the removal of the screen
//               onPress: () => navigation.goBack(),
//             },
//           ]
//         );

      

//        }
//       }
//       title="Info"
//       color="#fff"
//     >
//       <MaterialCommunityIcons
//         name="arrow-left"
//         size={22}
//         color="#fff"
//         style={{
//           height: 24,
//           width: 24,
//           marginLeft: 20,
//           marginBottom: 5

//         }}
//       />
//     </TouchableOpacity>
//   )
// }

function LogoTitle() {
  return (
    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
      <Image
        style={{ width: 150, height: 50 }}
        source={require("../assets/logo_white.png")}
      />
    </View>
  );
}

function Logout({ navigation }) {
  const dispatch = useDispatch();
  return (
    <View style={{ display: 'flex', flexDirection: "row" }}>
      <View style={{ display: 'flex', flex: 1, justifyContent: "flex-end", flexDirection: "row", alignItems: 'flex-end', }}>
        <Text style={{ color: 'white', fontWeight: 'bold', textAlign: 'center', fontSize: 18 }}>Dashboard</Text>
      </View>
      <View style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', flexDirection: "row", alignItems: 'flex-end' }}>
        <TouchableOpacity
          style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', flexDirection: "row" }}
          onPress={() => {
            Swal.fire({
              text: "Are you sure you want to logout?",
              showCancelButton: true,
              cancelButtonText: "Cancel",
              confirmButtonText: "OK",
              confirmButtonColor: "#F4981E",
              backdrop: false
            }).then((result) => {
              if(result.isConfirmed) {
                if (Platform.OS === 'web') {
                  //location.reload();
                  window.location.reload(false);
                } else {
                  dispatch(logout());
                  NativeModules.DevSettings.reload();
                }
              }
            });
          }}

        >
          <Text style={{ color: "white", }}>Logout</Text>
          <MaterialCommunityIcons
            name="account-circle"
            size={22}
            color="#fff"
            style={{
              height: 24,
              width: 24,
            }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}
const Stack = createStackNavigator();

export default function App() {
  return (
    <Provider store={store}>
      <NavigationContainer>
        <Stack.Navigator>
          <Stack.Screen name="Login" options={{
            headerTitle: props => <LogoTitle {...props} />, title: "Login", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold'

            }
          }} component={LoginScreen} />
          <Stack.Screen name="Forgot" options={{
            headerTitle: props => <LogoTitle {...props} />, title: "Forgot Password", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold'

            }
          }} component={ForgotScreen} />
          <Stack.Screen name="Register" options={{
            title: "Register", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',

            }
          }} component={RegisterScreen} />
          <Stack.Screen name="MainView" options={{
            headerTitle: props => <Logout {...props} />, title: "Records", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',

            }, headerLeft: null
          }} component={MainViewScreen} />
          <Stack.Screen name="RecordsView"
            options={{
              headerTitle: props => <TitleBar title="SUBMITTED FORMS" {...props} />,
              headerTintColor: '#ffffff',
              headerStyle: {
                backgroundColor: colors.primary,
              }
            }}
            component={RecordsViewScreen}
          />
          <Stack.Screen name="TaskDetail" options={{
            title: "", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',

            }
          }} component={TaskDetailScreen} />
          <Stack.Screen name="FormView" options={({ navigation, route }) => ({
            title: route.params.title,
            headerLeft: () => (
              // <ReturnIcon
                
              //   navigation={navigation}
              // />
              <TouchableOpacity
                onPress={() => {
                  Swal.fire({
                    text: "If you leave this form, data you have entered may not be saved. Are you sure you want to leave this form?",
                    confirmButtonText: "OK",
                    cancelButtonText: "Cancel",
                    showCancelButton: true,
                    confirmButtonColor: "#F4981E",
                    backdrop: false
                  }).then((result) => {
                    if(result.isConfirmed)
                      navigation.goBack();
                  })

                }

                }
                title="Info"
                color="#fff"
              >
                <MaterialCommunityIcons
                  name="arrow-left"
                  size={22}
                  color="#fff"
                  style={{
                    height: 24,
                    width: 24,
                    marginLeft: 20,
                    marginBottom: 5

                  }}
                />
              </TouchableOpacity>

            ),
            headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',

            },
          })} component={FormViewScreen} />
          <Stack.Screen name="NewTask" options={{
            title: "", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',

            }
          }} component={NewTaskScreen} />
          <Stack.Screen name="NewLogTask" options={{
            title: "", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',

            }
          }} component={NewLogTaskScreen} />
          <Stack.Screen name="GroupView" options={{
            title: "Groups", headerStyle: {
              backgroundColor: colors.primary,
            }, headerTintColor: '#ffffff',
            headerTitleStyle: {
              fontWeight: 'bold', textAlign: 'center',

            }
          }} component={GroupViewScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    </Provider>
  );
}