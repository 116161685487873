import { handleActions } from 'redux-actions';
import Immutable from 'immutable';
import { 
  LOGIN_REQUEST,
  LOGIN_RESPONSE,
  LOGOUT,
  AUTH_TOKENS_UPDATED,
  LOAD_STORAGE,
  LOGIN_LOADING_REPONSE,
  NAVIGATE_MENU,
  SET_PAYMENT_COMPLETED,
} from '../constants/actions';

import StorageService from '../services/storage';
import Swal from "sweetalert2";

const initialState = {
  username: '',
  authToken: '',
  refreshToken: '',
  loginLoading: false,
  payment_completed: true,
  companyTimezone: ''

};

export default handleActions({
  [LOGIN_REQUEST]: (state, action) => {
    //const { username, password } = action.payload;
    const { username } = action.payload;
    StorageService.set('username', username);
    //LocalStorageDriver.set('password', password);
    return state.set('username', username).set('loginLoading',true);
      //.set('password', password);
  },
  [LOGIN_RESPONSE]: {
    next(state, action) {
      return state;
    },
    throw(state, action) {
      Swal.fire({
        text: 'Login failed',
        confirmButtonText: "OK",
        confirmButtonColor: "#F4981E",
        backdrop: false

      });
      return state;
    },
  },
  [LOGIN_LOADING_REPONSE]: {
    next(state, action) {
      return state.set('loginLoading',false);
    },   
  },
  [LOGOUT]: (state, action) => {

    console.log('***** LOGOUT reducer!');

    StorageService.remove('username');
    StorageService.remove('authToken');
    StorageService.remove('refreshToken');
    StorageService.remove('companyTimezone');

    return state.set('authToken', '')
      .set('companyTimezone', '')
      .set('refreshToken', '');
  },
  [AUTH_TOKENS_UPDATED]: (state, action) => {
    if(action.payload){
    StorageService.set('authToken', action.payload.token);
    StorageService.set('refreshToken', action.payload.refresh);
    StorageService.set('companyTimezone', action.payload.companyTimezone);

    return state.set('authToken', action.payload.token)
      .set('companyTimezone', action.payload.companyTimezone)
      .set('refreshToken', action.payload.refresh);
    }else{
      Swal.fire({
        text: 'Wrong Email and Password',
        confirmButtonText: "OK",
        confirmButtonColor: "#F4981E",
        backdrop: false

      });
      return state.set('authToken', '')
      .set('refreshToken', '');
    }
  },
  [LOAD_STORAGE]: (state, action) => {
    console.log('LOAD STORAGE ********');
    return state.set('authToken', StorageService.get('authToken'))
      .set('companyTimezone', StorageService.get('companyTimezone'))
      .set('refreshToken', StorageService.get('refreshToken'));
  },
  [NAVIGATE_MENU]: (state, action) => {
    if(action.payload){
      const { token, refresh, payment_completed }= action.payload;
      
      return state.set('authToken', token)
        .set('refreshToken', refresh)
        .set('payment_completed', payment_completed);
    }else{
      return state.set('authToken', '')
        .set('refreshToken', '');
    }

  },
  [SET_PAYMENT_COMPLETED]: (state, action) => {
    const paid = action.payload;
    return state.set('payment_completed', paid);
  }
}, Immutable.fromJS(initialState));