import { createAction } from "redux-actions";
import {
  RECORD_GET_REQUEST,
  RECORD_INPROGRESS_RESPONSE,
  RECORD_EDITREQUIRED_RESPONSE,
  RECORD_READYFORREVIEW_RESPONSE,
  RECORD_READYFORAPPROVAL_RESPONSE,
  SET_RECORD_STATUS_LOADING,
  SET_RECORDS_LOADING,
  ADD_DATA_TO_LOGRECORDS,
  SET_TASK_COUNT,
  SET_TASK_PAGE,
  SET_TASKLOG_PAGE,
  SET_TASKLOG_COUNT,
  SET_TASKS_STATUS,
  SET_STATUS_CHANGE,
  UNSET_STATUS_CHANGE,
  SET_LOG_FILTER,
  PALLETS_REQUEST,
  PALLETS_LOAD
} from "../constants/actions";

import {  
  getAllRecords as _getAllRecords,
  recordInProgress as _rerecordInProgress,
  recordEditRequired as _recordEditRequired,
  recordComplete as _recordComplete,
  recordApproved as _recordApproved,
  getAllTaskByLog as _getAllTaskByLog,
  getLogGroupsSub,
  getMyInventoryTypes as _getMyInventoryTypes

} from "../api";
 
export function getAllTaskFromLog({page, filter}){
  return (dispatch, getState) => { 
    dispatch(createAction(SET_TASKS_STATUS)("loading"));  
    _getAllTaskByLog(dispatch,getState, {page, filter})
    .then( resp => {
      console.log(resp)
      // dispatch(createAction(SET_TASKLOG_COUNT)(resp.headers.get('X-Total-Records')));
      // dispatch(createAction(SET_TASKLOG_PAGE)(resp.headers.get('X-Current-Page')));
      dispatch(createAction(SET_TASKLOG_COUNT)(resp.taskCount));
      dispatch(createAction(SET_TASKLOG_PAGE)(resp.page));
      dispatch(createAction(ADD_DATA_TO_LOGRECORDS)(resp.logs));
      dispatch(createAction(SET_TASKS_STATUS)("idle"));
      return resp;
    });
  }
}
export function setLogFilter(filter){
  return (dispatch, getState) => { 
    dispatch(createAction(SET_LOG_FILTER)(filter));
  }
}


export function getAllRecords({page, filter = false, logPage}) {
  return (dispatch, getState) => { 
    dispatch(createAction(SET_RECORDS_LOADING)(true));   
    /*_getAllRecords(dispatch, getState).then(
      (response) => {        
        dispatch(createAction(RECORD_GET_REQUEST) (response)); 
        return response;
      });
      _getAllTaskByLog(dispatch,getState).then( resp => {
        dispatch(createAction(ADD_DATA_TO_LOGRECORDS)(resp));
        return resp;
    });*/
    _getAllRecords(dispatch, getState, {page, filter})
    .then( (resp) => {
      if(typeof page != 'undefined'){
        //dispatch(createAction(SET_TASK_COUNT)(resp.headers.get('X-Total-Records')));
        //dispatch(createAction(SET_TASK_PAGE)(resp.headers.get('X-Current-Page')));
        dispatch(createAction(SET_TASK_COUNT)(resp.length));
        dispatch(createAction(SET_TASK_PAGE)(page));
        dispatch(createAction(RECORD_GET_REQUEST)(resp));
        //return resp.json();
      }
      else{
        dispatch(createAction(RECORD_GET_REQUEST)(resp));
        //return resp;
      }
      //return resp.json();
    });

  };
}


export function recordInProgress(record) {
  return (dispatch, getState) => {    
    dispatch(createAction(SET_RECORD_STATUS_LOADING)(true));
    dispatch(createAction(RECORD_INPROGRESS_RESPONSE)(_recordInProgress(dispatch, getState,record)));
  };
}

export function recordEditRequired(record) {
  return (dispatch, getState) => {    
    dispatch(createAction(SET_RECORD_STATUS_LOADING)(true));
    dispatch(createAction(RECORD_EDITREQUIRED_RESPONSE)(_recordEditRequired(dispatch, getState,record)));
  };
}

export function recordComplete(record) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_RECORD_STATUS_LOADING)(true));
    //dispatch(createAction(RECORD_READYFORREVIEW_RESPONSE)(_recordComplete(dispatch, getState,record)));
    _recordComplete(dispatch, getState,record).then(
      (response) => {        
        dispatch(createAction(RECORD_READYFORREVIEW_RESPONSE) (response)); 
        return response;
      });
      
    
  };
}

export function recordApproved(record) {
  return (dispatch, getState) => {    
    dispatch(createAction(SET_RECORD_STATUS_LOADING)(true));
    dispatch(createAction(RECORD_READYFORAPPROVAL_RESPONSE)(_recordApproved(dispatch, getState,record)));
  };
}

export function setStatusChange() {
  return (dispatch, getState) => {    
    dispatch(createAction(SET_STATUS_CHANGE)(true));
  };
}

export function unsetStatusChange() {
  return (dispatch, getState) => {    
    dispatch(createAction(UNSET_STATUS_CHANGE)(false));
  };
}
export function getMyInventoryTypes(data, callback) {
  return (dispatch, getState) => {
      dispatch(createAction(PALLETS_REQUEST)(data?.page));
      return _getMyInventoryTypes(dispatch, getState, data).then((result) => {
          dispatch(createAction(PALLETS_LOAD)(result));
          if(callback)callback();
      }).catch((error)=>{
          console.log(error)
      })
  };
}
export function searchInventoryTypes(data, callback) {
  return (dispatch, getState) => {
      return _getMyInventoryTypes(dispatch, getState, data).then((result) => {
          if(callback)callback(result);
      }).catch((error)=>{
          console.log(error)
      })
  };
}
