import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";

export default class Textarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initial,
      height: 100,
      dialogVisible: false,
    };
  }
  componentWillReceiveProps(nextProps){
    if(nextProps.initial != this.props.initial){
      this.setState({value: nextProps.initial});
    }
  }
  // componentDidMount() {
  //   if(this.props.placeholder !== ''){
  //     var placeholderHeight = ((Math.ceil(this.props.placeholder.length / 50) * 20 )+50)
  //   placeholderHeight === 0 ? placeholderHeight = 128 : placeholderHeight
  //   if (this.state.value === '') {
  //     this.setState({
  //       height: placeholderHeight
  //     })
  //   } else {
  //     var valueHeight = ((Math.ceil(this.state.value.length / 50)*20)+50)
  //     this.setState({
  //       height: valueHeight
  //     })
  //   }
  //   }else{
  //     this.setState({
  //       height: 100
  //     })
  //   }
    
  // }

  // componentDidUpdate(prevProps, prevState) {
    
  //   if (prevState.value !== this.state.value) {
  //     if (this.state.value === '') {
  //       var placeholderHeight = ((Math.ceil(this.props.placeholder.length / 50) * 20 )+50)
  //       placeholderHeight === 0 ? placeholderHeight = 128 : placeholderHeight
  //       this.setState({
  //         height: placeholderHeight
  //       })
  //       if(this.props.placeholder === ''){
  //         this.setState({
  //           height: 100
  //         })
  //       }

  //     } else {
  //       var valueHeight = ((Math.ceil(this.state.value.length / 50) * 20 )+50)
  //       this.setState({
  //         height: valueHeight
  //       })
  //     }
  //   }
  // }

  getStyle() {
    var style = {
      fontSize: 20,
      color: "#424242",
      fontWeight: "400",
      height: 150,
      borderColor: "#e7e7e7",
      borderWidth: 1,
      borderRadius: 5,
      paddingLeft: 10,
      paddingTop: 10,
      fontFamily: "Roboto",
      dialogVisible: false,
    };

    if (this.props.label == "TK Trainer Comments") {
      style["color"] = "#d00";
      style["borderColor"] = "#d00";
    }

    return style;
  }

  render() {
    const { value } = this.state;
    const { label, onChange, id, isIssuable, onSubmitIssue, isCompleted, placeholder, width, openIssue, defaultComponent } = this.props;
    return (
      <>
        { !width ?
          <NewIssue  
            visible={this.state.dialogVisible} 
            id={id} 
            label={label} 
            onSubmitIssue={onSubmitIssue} 
            handleClose={()=>this.setState({dialogVisible:false})} 
            width={width}
          /> : null
        }

        <View style={styles.container, {
          paddingTop: width ? 0 : 5
        }}>
          <Text style={styles.label}>{label}</Text>
          {
            (isIssuable && isCompleted) &&
            <TouchableOpacity style={{ paddingBottom: 10, paddingTop: 5 }} onPress={() => {
              if(width){
                openIssue();
              }else{
                this.setState({dialogVisible:true});
              }
            }}>
              <Text style={{ color: '#002F6C', fontWeight: 'bold' }}>Issue</Text>
            </TouchableOpacity>
          }

          <TextInput
            value={value}
            editable={!defaultComponent}
            // style={this.getStyle()}
            multiline={true}
            onContentSizeChange={(event) => {
              setTimeout(() => {
                this.setState({ height: event.nativeEvent.contentSize.height });
              }, 5);
            }}
            style={{
              borderColor: "#e7e7e7",
              borderRadius: 5,
              borderWidth: 1,
              color: "#424242",
              fontFamily: "Roboto",
              fontSize: 20,
              fontWeight: "400",
              padding: 5, height: this.state.height,
              paddingTop: 5,
              fontStyle: this.state.value.length == 0 ? 'italic' : 'normal'
            }}
            placeholder={this.props.placeholder}
            placeholderTextColor={'rgb(0, 47, 108)'}
            onChangeText={(val) => {
              if(val == '' && this.props.placeholder == ''){
                this.setState({ value: val, height: 100 });

              }else{
                this.setState({ value: val });

              }
              onChange(id, val);
            }}
          />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    alignItems: "stretch",
    flexDirection: "column",
    // paddingLeft: 10,
    // paddingRight: 10,
    paddingTop: 5,
    // paddingBottom: 10,
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
});
