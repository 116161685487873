import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";

export default class LotCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initial,
      dialogVisible: false,
      height: 30,
    };
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.initial != this.props.initial){
      this.setState({value: nextProps.initial});
    }
  }
  generateLotCode(max,min){
    const {id, submissionSelected, onChange, lotConsecutive} = this.props;
    var val = submissionSelected?._id ? submissionSelected._id.slice(-8) + lotConsecutive : Math.floor(Math.random() * (max - min) + min);
    onChange(id, val);
    this.setState({ value: val });
  }
  render() {
    const { value } = this.state;
    const { id, onChange, label,customlot, isIssuable, onSubmitIssue, isCompleted, submissionSelected, showLotGenerator, width, openIssue, defaultComponent} = this.props;
    return (
      <>  
      { !width ?
          <NewIssue  
            visible={this.state.dialogVisible} 
            id={id} 
            label={label} 
            onSubmitIssue={onSubmitIssue} 
            handleClose={()=>this.setState({dialogVisible:false})} 
            width={width}
          /> : null
        }

      <View style={styles.container, {
        paddingTop: width ? 0 : 5
      }}>
        {!customlot?
          <Text style={styles.label}>{label}</Text>:null
        }
        {
          (isIssuable && isCompleted) && 
          <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
            if(width){
              openIssue();
            }else{
              this.setState({dialogVisible:true});
            }
          }}>
            <Text style={{color:'#002F6C', fontWeight:'bold'}}>Issue</Text>  
          </TouchableOpacity>
        }

        <TextInput
          value={value}
          editable={!defaultComponent}
          onContentSizeChange={(event) => {
              if(event.nativeEvent.contentSize.height == 0){
                if(this.props.placeholder == ''){
                  this.setState({height: 30})
                }
                this.setState({height: event.nativeEvent.contentSize.height});
              }else{
                setTimeout(() => {
                  this.setState({height: event.nativeEvent.contentSize.height});
                }, 5)
              }
            }}
            style={{ 
            borderColor: "#e7e7e7",
            borderRadius: 5,
            borderWidth: 1,
            color: "#424242",
            fontFamily: "Roboto",
            fontSize: 20,
            fontWeight: "400",
            padding: 5, 
            height: this.state.height,
            fontStyle: this.state.value == "" || !this.state.value ? 'italic' : 'normal'
          }}
          multiline={true}
          placeholder={this.props.placeholder}
          placeholderTextColor={'rgb(0, 47, 108)'}
          onChangeText={(val) => {
            if(val == '' && this.props.placeholder == ''){
              this.setState({ value: val, height: 30 });

            }else{
              this.setState({ value: val });

            }
            onChange(id, val);
          }}
          
        />
        {
          showLotGenerator &&
            <TouchableOpacity style={styles.btnLotCode} onPress={() => { this.generateLotCode(999999999999,10000000)}}>
              <Text style={styles.txtLotCode}>Generate Lot Code</Text>  
            </TouchableOpacity>
        }
      </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    alignItems: "stretch",
    flexDirection: "column",
    // paddingLeft: 10,
    // paddingRight: 10,
    paddingTop: 5,
    // paddingBottom: 10,
  },
  input: {
    fontSize: 22,
    color: "#424242",
    fontWeight: "400",
    alignSelf: "stretch",
    height: 50,
    flex: 1,
    borderStyle: "solid",
    borderColor: "#e7e7e7",
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
    fontFamily: "Roboto",
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
  btnLotCode:{
    padding: 10,
    textAlign: "center",
    marginTop: 10,
    borderRadius: 5,
    backgroundColor: '#002f6c'
  },
  txtLotCode:{
    color: 'white',
    fontSize: 18
  }
});
